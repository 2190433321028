body {
  overflow: hidden;
}

#mynetwork {
  width: 100%;
  border: 1px solid lightgray;
}

/* size to text sidebar menu */
.text-size {
  font-size: 12px;
}

/* size to text sidebar menu items */
.text-size-sm {
  font-size: 10px;
}

/* size to text menu top*/
.ts-menu {
  font-size: 15px;
}

/* size to text menu items top*/
.ts-menu-sm {
  font-size: 12px;
}

.device-img {
  width: 50px;
  height: 20px;
 /* background-size: contain;*/
}

.device-img-hosts {
  width: 20px;
  height: 30px;
}

ul {
  padding: 0;
  margin: 0px;
  list-style: none;
}

li {
  margin: 0px;
}

.li-img-components {
  width: 48px;
  height: 48px;
  border: 1px solid lightgray;
  /*border-radius: 10px;*/
  padding: 5px;
}

#sidebar-container {
  min-height: 100vh;
}

#sidebar-container #menu-sidebar {
  width: 10rem;
}

#content {
  overflow-y: auto;
  height: 100vh;
  padding-bottom: 5rem;
}

.bi {
  vertical-align: -.125em;
  pointer-events: none;
  fill: currentColor;
}

.dropdown-toggle {
  outline: 0;
}

.nav-flush .nav-link {
  border-radius: 0;
}

.btn-toggle {
  display: inline-flex;
  align-items: center;
  padding: .25rem .5rem;
  font-weight: 600;
  color: rgba(0, 0, 0, .65);
  background-color: transparent;
  border: 0;
}

.btn-toggle:hover,
.btn-toggle:focus {
  color: rgba(0, 0, 0, .85);
  background-color: #d2f4ea;
}

.btn-toggle::before {
  width: 1.25em;
  line-height: 0;
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
  transition: transform .35s ease;
  transform-origin: .5em 50%;
}

.btn-toggle[aria-expanded="true"] {
  color: rgba(0, 0, 0, .85);
}

.btn-toggle[aria-expanded="true"]::before {
  transform: rotate(90deg);
}

.btn-toggle-nav a {
  display: inline-flex;
  padding: .1875rem .5rem;
  margin-top: .125rem;
  margin-left: 1.25rem;
  text-decoration: none;
}

.btn-toggle-nav a:hover,
.btn-toggle-nav a:focus {
  background-color: #d2f4ea;
}

.scrollarea {
  overflow-y: auto;
}

.fw-semibold {
  font-weight: 600;
}

.lh-tight {
  line-height: 1.25;
}

@media (max-width: 768px) {
  #sidebar-container {
    display: none;
  }
}