#external-events {
    z-index: 2;
    top: 20px;
    left: 20px;
    padding: 0 10px;
    border: 1px solid #ccc;
    background: #eee;
    margin-top: 30px;
}
  
#external-events .fc-event {
    margin: 1em 0;
    padding-left: 0.2em;
    padding-right: 0.2em;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    cursor: move;
    color: white;
    background-color: dodgerblue;
    border-radius: 5px;
}